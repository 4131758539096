import PostForm from "./Form/PostForm";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {postRequest} from "../utils/requestUtils";
import DialogPanel from "./DialogPanel";


export default function PostPanel({visible, toggle}) {
  const [isLoading, setLoading] = useState(false)
  const [newPost, setNewPost] = useState({
    content: '',
    postId: null,
  })

  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    postRequest('/posts/', newPost).then(({data}) => {
      navigate('/post/' + data.id)
      toggle()
      setNewPost({
        content: '',
        postId: null,
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleChange = (e) => {
    const value = e.target.value
    setNewPost({...newPost, ...{content: value}})
  }

  return (
    <DialogPanel visible={visible} toggle={toggle} height={'495px'}>
      <h3>Nouveau piou</h3>
      <PostForm post={newPost} handleChange={handleChange} handleSubmit={handleSubmit} isLoading={isLoading} />
    </DialogPanel>
  )
}