import {Link } from "react-router-dom";

function UserInfos ({ user, className }) {
  if (!user) {
    return
  }

  return (
    <Link to={`/user/${user.username}`} className={`text-decoration-none w-auto mt-1 ${className}`}>
      <h2 className="my-0 fw-bold fs-6">{ user.nickname }</h2>
      <small className="my-0 text-muted">@{ user.username && user.username.toLowerCase() }</small>
    </Link>
  )
}

export default UserInfos