import DialogPanel from "./DialogPanel";
import {useEffect} from "react";
import {getRequest, postRequest} from "../utils/requestUtils";
import {Link} from "react-router-dom";

function NotificationsPanel ({visible, toggle, notifications, setNotifications}) {
  const notificationsTypes = {
    1: 'like',
    2: 'reply',
    3: 'share',
    4: 'quote',
    5: 'follow'
  }

  useEffect(() => {
      getRequest('/notifications/').then(({data}) => {
        setNotifications(data)
      })
  }, [visible])

  const requestNotificationRead = (id) => {
    postRequest('/notifications/read/', {
      notifId: id
    })
  }

  const handleClick = (notification) => {
    toggle()

    notification.read = true
    setNotifications([...notifications, notification])

    requestNotificationRead(notification.id)
  }

  return (
    <DialogPanel visible={visible} toggle={toggle} height="100%">
      <div>
        <h2>Notifications</h2>
        { notifications && notifications.map((notification) => {
          let textAction
          let linkTo

          switch (notification.action) {
            case 1:
              textAction = ' a aimé votre Piou'
              linkTo = '/post/' + notification.entityId
              break
            case 2:
              textAction = ' a répondu à votre Piou. '
              linkTo = '/post/' + notification.entityId
              break
            case 3:
              textAction = ' a repiouté.'
              linkTo = '/post/' + notification.entityId
              break
            case 4:
              textAction = ' a cité votre Piou.'
              linkTo = '/post/' + notification.entityId
              break
            case 5:
              textAction = ' vous suit.'
              linkTo = `/user/${notification.byUser.username}` // Link to the user page
              break
            default:
          }

          return (
            <div key={`notif-${notification.id}`}>
              <Link to={linkTo} onClick={() => handleClick(notification)} className={`p-4 m-0 d-block w-100 text-decoration-none ${notification.read && 'text-muted'}`}>
                { !notification.read && <i className="pi pi-circle-fill text-danger me-3" style={{marginLeft: '-15px', fontSize: '6px'}}></i>}
                <b>{notification.byUser.nickname}</b> {textAction}
              </Link>
              <hr className="m-0" />
            </div>
          )
        })}
      </div>
    </DialogPanel>
  )
}

export default NotificationsPanel