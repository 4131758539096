import useCurrentUser from "../hooks/useCurrentUser";
import {Button} from "primereact/button";


export default function UpdateProfileButton({ user, toggle }) {
  const currentUser = useCurrentUser()

  if (currentUser.id === user.id) {
    return <Button onClick={toggle} label="Modifier" size="small" />
  }
}