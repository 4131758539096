import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {postRequest} from "../utils/requestUtils";
import formUtils from "../utils/formUtils";
import {Link} from "react-router-dom";
import {Divider} from "primereact/divider";
import {useRef, useState} from "react";
import {Toast} from "primereact/toast";

const Login = ({ user, setUser }) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = formUtils(e.target)

    setIsLoading(true)

    postRequest('/login', formData).then(({data}) => {
      if (data && data.token) {
        setUser(data.user)
        localStorage.setItem('user', JSON.stringify(data.user))
        localStorage.setItem('token', data.token)
      }
    }).catch((error) => {
      if (error.response.status === 403) {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Impossible de vous connecter avec cette adresse email et/ou ce nom d'utilisateur.", life: 5000 });
      } else {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Impossible de joindre le serveur", life: 5000 });
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="row mt-5">
        <div className="col-10 offset-1 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <Card>
            <div className="text-center mb-4">
              <img src="/favicon.svg" alt="Piouteur" width="50" title="Piouteur" />
            </div>
            <h3 className="p-card-title ms-2 mb-3">Me connecter à Piouteur</h3>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="p-input-icon-right m-2">
                <i className="pi pi-at" />
                <InputText name="email" type="email" placeholder="Email" required />
              </div>
              <div className="m-2">
                <Password name="password" placeholder="Mot de passe" toggleMask feedback={false} required />
              </div>
              <Button label="Me connecter" type="submit" className="m-2" loading={isLoading} disabled={isLoading} />
            </form>
            <Divider align="center">
              ou
            </Divider>
            <div className="text-center">
              <Link to={`/register`}>M'inscrire à Piouteur</Link>
            </div>
          </Card>
        </div>
      </div>
      <p className="p-1 text-center">
        <small className="text-muted">
          Piouteur &copy; <a href="https://plumia.net" target="_blank" rel="noreferrer" className="text-decoration-none">Plumia</a>
        </small>
      </p>
    </>
  )
}

export default Login