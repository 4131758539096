import ReplyingTo from "./ReplyingTo";
import SharedBy from "./SharedBy";
import PostContent from "./PostContent";
import PostButtons from "./PostButtons";

function PostComponent({ post }) {
  const sharedPostContent = (post) => {
    if (post.sharedPost) {
      // Quote Repioute
      if (post.content) {
        return <>
          <PostContent post={post} />
          <div className="p-4 border border-1 border-gray rounded-3">
            <PostContent post={post.sharedPost} />
          </div>
          <PostButtons post={post} />
        </>
      }

      // Repioute
      return <>
        <PostContent post={post.sharedPost} />
        <PostButtons post={post.sharedPost} />
      </>
    }

    // Piou
    return <>
      <PostContent post={post} />
      <PostButtons post={post} />
    </>
  }

  return (
    <>
      <article className="px-4 pt-3">
        <ReplyingTo post={post} />
        <SharedBy post={post} />
        {sharedPostContent(post)}
      </article>
      <hr className="m-0" />
    </>
  )
}

export default PostComponent