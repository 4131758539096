import {Card} from "primereact/card";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import {Link, useNavigate} from "react-router-dom";
import formUtils from "../utils/formUtils";
import {postRequest} from "../utils/requestUtils";
import {useRef, useState} from "react";
import {Toast} from "primereact/toast";

function Register () {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useRef(null)
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = formUtils(e.target)

    setIsLoading(true)

    postRequest('/register', formData).then(({data}) => {
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Félicitations ! Votre compte Piouteur a été créeé. Vous allez être redirigé vers le formulaire de connexion.', life: 5000 });
        setTimeout(() => {
          navigate('/')
        }, 5000)
    }).catch(({response}) => {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Impossible de créer votre compte avec cette adresse email et/ou ce nom d'utilisateur.", life: 5000 });
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="row mt-5">
        <div className="col-10 offset-1 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <Card>
            <div className="text-center mb-4">
              <img src="/favicon.svg" alt="Piouteur" width="50" title="Piouteur" />
            </div>
            <h3 className="p-card-title ms-2 mb-3">M'inscrire à Piouteur</h3>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="p-input-icon-right m-2">
                <i className="pi pi-at" />
                <InputText name="email" keyfilter="email" type="email" placeholder="Email" required />
              </div>
              <div className="p-input-icon-right m-2">
                <i className="pi pi-user" />
                <InputText name="username" keyfilter="alphanum" placeholder="Nom d'utilisateur" required tooltip="Votre nom d'utilisateur correspond à un identifiant unique. Il ne sera pas modifiable." tooltipOptions={{ event: 'focus', position: 'top' }} />
              </div>
              <div className="p-input-icon-right m-2">
                <i className="pi pi-user" />
                <InputText name="nickname" placeholder="Votre pseudo" required tooltip="Votre pseudo sera visible par les autres utilisateurs. Vous pouvez mettra des emojis ou des caractères spéciaux. Il sera modifiable après inscription." tooltipOptions={{ event: 'focus', position: 'top' }} />
              </div>
              <hr/>
              <div className="m-2">
                <Password name="password" placeholder="Mot de passe" toggleMask feedback={false} required />
              </div>
              <div className="m-2">
                <Password name="password_confirm" placeholder="Confirmez le mot de passe" toggleMask feedback={false} required />
              </div>
              <Button label="M'inscrire" type="submit" className="m-2" loading={isLoading} disabled={isLoading} />
            </form>
            <Divider align="center">
              ou
            </Divider>
            <div className="text-center">
              <Link to={`/`}>Me connecter</Link>
            </div>
          </Card>
        </div>
      </div>
      <p className="p-1 text-center"><small className="text-muted">Piouteur &copy; <a href="https://plumia.net" target="_blank" rel="noreferrer">Plumia</a></small></p>
    </>
  )
}

export default Register