import useCurrentUser from "../hooks/useCurrentUser";
import {Button} from "primereact/button";
import {postRequest} from "../utils/requestUtils";
import {useEffect, useState} from "react";

export default function FollowButton ({user, setUser}) {
  const [isLoading, setLoading] = useState(false)
  const currentUser = useCurrentUser()
  const [doesCurrentUserFollowThisOne, setDoesCurrentUserFollowThisOne] = useState(false)

  useEffect(() => {
    if (user && user.followers) {
      setDoesCurrentUserFollowThisOne(user.followers.some(follower => follower.user.id === currentUser.id))
    }
  }, [user])

  const handleClick = () => {
    setLoading(true)

    postRequest('/follows/', {
      user: user,
    }).then(({data}) => {
      setUser({...user, ...{followers: data}})
    }).finally(() => {
      setLoading(false)
    })
  }

  if (doesCurrentUserFollowThisOne) {
    return <Button onClick={handleClick} label="Abonné" icon="pi pi-user-minus" size="small" outlined loading={isLoading} disabled={isLoading} />
  }

  if (user.id !== currentUser.id) {
    return <Button onClick={handleClick} label="Follow" icon="pi pi-user-plus" size="small" loading={isLoading} disabled={isLoading} />
  }

  return null
}