import {Button} from "primereact/button";
import {postRequest} from "../utils/requestUtils";
import {useEffect, useState} from "react";
import useCurrentUser from "../hooks/useCurrentUser";

function LikeButton ({post}) {
  const currentUser = useCurrentUser()
  const [likes, setLikes] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLikes(post.likes || [])
  }, [post])

  const doesUserLikeIt = () => {
    if (likes) {
      return likes.some(like => like.userId === currentUser.id)
    }
  }

  const handleClick = () => {
    setLoading(true)

    postRequest(`/likes/`, {
      post: post,
    }).then(({data}) => {
      setLikes(data || [])
    }).finally(() => {
      setLoading(false)
    })
  }

  const count = () => {
    return likes.length
  }

  return (
    <Button
      onClick={handleClick}
      label={count()}
      icon={ doesUserLikeIt() ? 'pi pi-heart-fill' : 'pi pi-heart'}
      className="p-button-text"
      loading={isLoading}
      disabled={isLoading}
      style={ doesUserLikeIt() ? {color: 'var(--pink-500)'} : {}}
    />
  )
}

export default LikeButton