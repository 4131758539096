import CommentsButton from "./CommentsButton";
import ShareButton from "./ShareButton";
import LikeButton from "./LikeButton";

export default function PostButtons({ post }) {
  return (
    <nav className="navbar justify-content-around">
      <CommentsButton post={post} />
      <ShareButton post={post} />
      <LikeButton post={post} />
    </nav>
  )
}