import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

function Logout ({ setUser }) {
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setUser({})
    navigate('/')
  }, [])

  return null
}

export default Logout