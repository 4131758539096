import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";

function CommentsButton ({ post }) {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/post/${post.id}`)
  }

  return (
    <Button onClick={handleClick} icon="pi pi-comment" label={post.posts && post.posts.length} className="p-button-text" />
  )
}

export default CommentsButton