import Avatar from "../components/Avatar";
import UserInfos from "../components/UserInfos";
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {getRequest} from "../utils/requestUtils";
import PostComponent from "../components/PostComponent";
import {Button} from "primereact/button";
import { Alert } from 'react-bootstrap'
import FollowButton from "../components/FollowButton";
import UpdateProfileButton from "../components/UpdateProfileButton";
import {Skeleton} from "primereact/skeleton";
import SkeletonPost from "../components/SkeletonPost";

function User ({toggle}) {
  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const { username } = useParams()

  useEffect(() => {
    setLoading(true)

    getRequest('/users/' + username).then(({data}) => {
      setUser(data)
    }).finally(() => {
      setLoading(false)
    })
  }, [username])

  if (isLoading) {
    return <>
      <header className="d-flex justify-content-between p-4">
        <div className="d-flex">
          <Skeleton shape="circle" size="4rem" />
          <div className="ms-2">
            <Skeleton width="10rem" height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <div>
          <Skeleton width="6rem" height="3rem" className="mb-2"></Skeleton>
        </div>
      </header>
      <SkeletonPost />
      <SkeletonPost />
      <SkeletonPost />
      <SkeletonPost />
      <SkeletonPost />
    </>
  }

  if (!user) {
    return (
      <Alert variant={`info`} as={`p`}>Profil introuvable</Alert>
    )
  }

  return (
    <>
      <header className="d-flex justify-content-between p-4">
        <div className="d-flex">
          <Avatar user={user} size="xlarge" />
          <UserInfos user={user} className="ms-2 py-2" />
        </div>
        <div>
          <FollowButton user={user} setUser={setUser} />
          <UpdateProfileButton user={user} toggle={toggle} />
        </div>
      </header>
      <nav className="d-flex justify-content-around">
        <Button type="button" label={`${user.followers && user.followers.length} followers`} className="p-button-text" />
      </nav>
      { user && user.posts && user.posts.reverse().map((post) => (
        <PostComponent key={post.id} post={post} />
      )) }
    </>
  )
}

export default User