import {Link} from "react-router-dom";

function SharedBy({ post }) {
  return (
    post.sharedPost && !post.content && (
      <p style={{marginLeft: "48px"}}>
        <i className="pi pi-share-alt"></i>&nbsp;
        Repiouté par&nbsp;
        <Link to={`/user/${post.sharedBy.username}`} className="text-decoration-none">@{post.sharedBy.username}</Link>
      </p>
    )
  )
}

export default SharedBy