import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {getRequest, postRequest} from "../utils/requestUtils";
import PostComponent from "../components/PostComponent";
import PostForm from "../components/Form/PostForm";
import SkeletonPost from "../components/SkeletonPost";

function PostView() {
  const [isPostLoading, setPostLoading] = useState(false)
  const { id } = useParams()
  const [post, setPost] = useState({})
  const [newPost, setNewPost] = useState({
    content: '',
    post: post,
  })
  const [isLoading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    setPostLoading(true)

    getRequest('/posts/' + id).then(({data}) => {
      setPost(data)
      setNewPost({...newPost, post: data})
    }).finally(() => {
      setPostLoading(false)
    })
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
    postRequest('/posts/', newPost).then(({data}) => {
      getRequest('/posts/' + id).then(({data}) => {
        setPost(data)
        setNewPost({...newPost, content: ''})
      })

      setIsSubmitted(true)
      setTimeout(() => {

        setIsSubmitted(false)
      }, 2000)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleChange = (e) => {
    setNewPost({...newPost, ...{content: e.target.value}})
  }

  if (isPostLoading) {
    return <SkeletonPost />
  }

  if (!post) {
    return (<p className="alert alert-warning m-4">Impossible de charger ce Piou.</p> )
  }

  return (
    <>
      <PostComponent post={post} />
      <div className="p-3">
        <PostForm post={newPost} handleSubmit={handleSubmit} handleChange={handleChange} isLoading={isLoading} isSubmitted={isSubmitted} />
      </div>
      {
        post.posts && post.posts.map((comment) => <PostComponent key={`post-${comment.id}`} post={comment} />)
      }
    </>
  )
}

export default PostView