import {Button} from "primereact/button";
import {postRequest} from "../utils/requestUtils";

function ShareButton ({post}) {

  const handleClick = () => {
    postRequest(`/posts/`, {
      content: '',
      post: post,
      isShared: true,
    }).then(({response}) => {
      alert('Post partagé !')
    })
  }

  return (
    <Button icon="pi pi-share-alt" onClick={handleClick} className="p-button-text" />
  )
}

export default ShareButton