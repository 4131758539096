import {Skeleton} from "primereact/skeleton";

export default function SkeletonPost () {
  return <>
    <div className="px-4 pt-3">
      <div className="d-flex justify-content-between gap-1">
        <div className="d-flex align-items-start gap-1">
          <Skeleton shape="circle" size="3rem" />
          <div className="ms-2">
            <Skeleton width="10rem" height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
      </div>
      <div className="my-2" style={{marginLeft: '60px'}}>
        <Skeleton height="3rem" />
      </div>
    </div>
    <hr/>
  </>
}