import {Link} from "react-router-dom";
import {Avatar as AvatarPrime} from "primereact/avatar";

function Avatar({user, size = 'large', isLink = true, onClick}) {
  if (!user) {
    return ''
  }

  const avatar = <AvatarPrime
    label={ user.nickname && user.nickname[0].toUpperCase()}
    image={user.avatar && user.avatar.image ? (`${process.env.REACT_APP_OMNICORE_API_URL}/files/${user.avatar.image.name}`) : undefined}
    shape="circle"
    size={size}
    className="text-decoration-none"
    onClick={onClick}
  />

  if (!isLink) {
    return avatar
  }

  return (
    <Link to={`/user/${user.username}`}>
      { avatar }
    </Link>
  )
}

export default Avatar