import {Mention} from "primereact/mention";
import {Button} from "primereact/button";
import styled from 'styled-components'
import CharCounter from "../CharCounter";

const StyledMention = styled(Mention)`
  textarea {
    width: 100%
  }
`

const PostForm = ({post, handleChange, handleSubmit, isLoading = false, isSubmitted = false}) => {
  const icon = isSubmitted ? `pi pi-check` : `pi pi-send`
  const color = isSubmitted ? `success` : `primary`

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="py-2">
        <StyledMention trigger={['@', '#']} field="content" placeholder="Exprimez-vous !" value={post.content} onChange={handleChange} className="w-100 mb-2" rows={2} maxLength={220} autoResize required />
        <div className="d-flex justify-content-between">
          <CharCounter post={post} />
          <Button icon={icon} className="px-5" label="Piouter" loading={isLoading} disabled={isLoading} severity={color} />
        </div>
      </form>
    </>
  )
}

export default PostForm