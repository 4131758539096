import {Link} from "react-router-dom";

function ReplyingTo ({post}) {
  return (
    post.replyingTo && !post.isShared && (
      <p className="fs-6" style={{marginLeft: "48px"}}>
        <i className="pi pi-comment"></i>&nbsp;
        En réponse à&nbsp;
        <Link to={`/user/${post.replyingTo.user.username}`} className="text-decoration-none">@{post.replyingTo.user.username}</Link>
      </p>
    )
  )
}

export default ReplyingTo