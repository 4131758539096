import {useEffect, useState} from "react";


export default function CharCounter({post}) {
  const [countChars, setCountChars] = useState(0)
  const [color, setColor] = useState('success')

  useEffect(() => {
    setCountChars(post.content.length)

    if (post.content.length < 200) {
      setColor('success')
    }

    if (post.content.length >= 200) {
      setColor('warning')
    }

    if (post.content.length >= 215) {
      setColor('danger')
    }
  }, [post])


  return <p className={`ps-1 text-muted`} style={{fontSize: '12px'}}><span className={`text-${color}`}>{countChars}</span>/220</p>
}