import axios from 'axios'

const {REACT_APP_PIOUTEUR_BACKEND} = process.env
export const getHeaders = () => {
  let token = {}

  if (localStorage.getItem('token')) {
    token = { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }

  return {
    'Content-Type': 'application/json',
    ...token,
  }
}

export const proxy = REACT_APP_PIOUTEUR_BACKEND

export const getRequest = async (route) => {
  return await axios.get(proxy + route, {
    headers : {...getHeaders()}
  })
}

export const postRequest = async (route, data) => {
  return await axios.post(proxy + route, data, {
    headers : {...getHeaders()}
  })
}

export const putRequestWithFiles = async (route, data) => {
  return await axios.put(proxy + route, data, {
    headers : {...getHeaders(), ...{'Content-Type': 'multipart/form-data',}}
  })
}

export const putRequest = async (route, data) => {
  return axios.put(proxy + route, data, {
    headers : {...getHeaders()}
  }).catch((error) => {
    //toast.error('Erreur')
  })
}

export const deleteRequest = async (route) => {
  return axios.delete(proxy + route, {
    headers : {...getHeaders()}
  }).catch((error) => {
    //toast.error('Erreur')
  })
}
