import DialogPanel from "./DialogPanel";
import {Link} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {useRef, useState} from "react";
import {debounce} from "radash";
import {getRequest} from "../utils/requestUtils";
import Avatar from "./Avatar";
import UserInfos from "./UserInfos";

export default function SearchPanel ({ visible, toggle }) {
  const search = useRef('')
  const [results, setResults] = useState([])
  const [isLoading, setLoading] = useState(false)

  const handleInput = async () => {
    const searchValue = search.current.value

    if (!searchValue) {
      return setResults([])
    }

    setLoading(true)

    getRequest('/search/' + searchValue).then(({data}) => {
      setResults(data)
    }).finally(() => {
      setLoading(false)
    })
  }

  const result = () => {
    if (results === []) {
      return 'Aucun résultat.'
    }

    if (search.current.value && results) {
      return results.map((result) =>
        <>
          <Link to={`/user/${result.username}`} key={`result-${result.username}`} onClick={toggle} className="d-block mb-3 text-decoration-none border-gray border rounded">
            <div key={`result-${result.username}`} className="px-4 py-3 d-flex">
              <Avatar user={result} size="large" />
              <UserInfos user={result} className="ms-3" />
            </div>
          </Link>
        </>
      )
    }
  }

  return <DialogPanel visible={visible} toggle={toggle} height="100%">
    <h2>Recherche</h2>
    <span className="p-input-icon-left w-100 mb-3">
      { isLoading ? <i className="pi pi-spin pi-spinner text-info" /> : <i className="pi pi-search"></i> }
      <InputText type={"text"} ref={search} onInput={debounce({delay: 500}, handleInput)} className="w-100" />
    </span>
    { result() }
  </DialogPanel>
}