import PostComponent from "../components/PostComponent";
import {useEffect, useState} from "react";
import {getRequest} from "../utils/requestUtils";
import {Message} from "primereact/message";
import SkeletonPost from "../components/SkeletonPost";

function PostsView () {
  const [isLoading, setLoading] = useState(false)
  const [posts, setPosts] = useState([])

  useEffect(() => {
    setLoading(true)

    getRequest('/posts/').then(({data}) => {
      setPosts(data)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  if (isLoading) {
    return <>
      <SkeletonPost />
      <SkeletonPost />
      <SkeletonPost />
      <SkeletonPost />
      <SkeletonPost />
    </>
  }

  if (!posts || posts.length === 0) {
    return (
      <div className="text-center p-4">
        <Message severity="info" text="Pas encore de Pious ici." />
      </div>
    )
  }

  return (
    posts.map((post) => (
      <PostComponent key={post.id} post={post} />
    ))
  )
}

export default PostsView