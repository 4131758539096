import DialogPanel from "./DialogPanel";
import {Link} from "react-router-dom";


export default function ProfilePanel ({ visible, toggle, user }) {
  return (
    <DialogPanel visible={visible} toggle={toggle} height={'300px'}>
      <Link onClick={toggle} to={`/user/${user.username}`} className="p-button d-block  w-100 text-center text-decoration-none">
        <i className="pi pi-user me-2"></i> Mon profil
      </Link>
      <hr/>
      <Link onClick={toggle} to="/logout" className="p-button d-block w-100 text-center text-decoration-none">
        <i className="pi pi-power-off me-2"></i> Me déconnecter
      </Link>
    </DialogPanel>
  )
}