import Avatar from "./Avatar";
import UserInfos from "./UserInfos";
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)

export default function PostContent ({post}) {
  const postDate = dayjs(post.createdAt)
  const dateToDisplay = postDate.isToday() ? `aujourd'hui à ${postDate.format('HH:mm')}` : postDate.format('DD/MM/YYYY à HH:mm')

  return (
    <>
      <article className="d-flex justify-content-between gap-1">
        <div className="d-flex align-items-start gap-1">
          <Avatar user={post.user} />
          <UserInfos user={post.user} />
        </div>
        <time dateTime={post.createdAt} style={{fontSize: '14px'}} className="text-muted">
          { dateToDisplay }
        </time>
      </article>
      <p className="my-2" style={{marginLeft: '48px'}}>{ post.content }</p>
    </>
  )
}