import DialogPanel from "./DialogPanel";
import {useEffect, useState, useRef } from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {putRequestWithFiles} from "../utils/requestUtils";
import formUtils from "../utils/formUtils";
import {Toast} from "primereact/toast";
import {Avatar} from "primereact/avatar";

export default function UpdateProfilePanel ({ visible, toggle, user, setUser }) {
  const toast = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [updatedUser, setUpdatedUser] = useState({})
  const fileInput = useRef(null)
  const [avatar, setAvatar] = useState(null)

  useEffect(() => {
    if (user && user.avatar && user.avatar.image) {
      setAvatar(`${process.env.REACT_APP_OMNICORE_API_URL}/files/${user.avatar.image.name}`)
    }

    setUpdatedUser({
      nickname: user.nickname,
      biography: user.biography,
    })
  }, [user])

  const handleChange = (e) => {
    const {name, value} = e.target

    setUpdatedUser({...updatedUser, ...{[name]: value}})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const formData = formUtils(e.target)

    putRequestWithFiles('/users/', formData).then(({data}) => {
      localStorage.setItem('user', JSON.stringify(data))
      setUser(data)
      toast.current.show({severity:'success', summary: 'Modification réussie', detail:'Votre profil a été mis à jour.', life: 5000});
    }).catch((error) => {
      toast.current.show({severity:'error', summary: 'Erreur', detail:'Impossible de mettre à jour votre profil pour le moment.', life: 5000});
    }).finally(() => {
      setLoading(false)
    })
  }

  // Fire input file on button click
  const handleClick = () => {
    fileInput.current.click()
  }

  const handleFileChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0])
    setAvatar(url)
  }

  return (
    <DialogPanel visible={visible} toggle={toggle} height={'100%'}>
      <Toast ref={toast} position="top-center" />
      <h3 className="mb-4">Modifier votre profil</h3>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="mb-3">
          <i className="pi pi-pencil text-white" style={{position: "relative", top: '-5px', left: '37px'}}></i>
          <Avatar image={avatar} label={'P'} onClick={handleClick} user={user} isLink={false} size="xlarge" shape="circle"  />
        </div>
        <input type="file" accept="image/*" ref={fileInput} name="avatar" onChange={handleFileChange} className="visually-hidden" />
        <div className="d-flex justify-content-between align-items-center mb-2">
          <label htmlFor="username">Pseudo</label>
          <InputText name="nickname" id="username" value={updatedUser.nickname} onChange={handleChange} aria-describedby="username-help" />
        </div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <label htmlFor="bio">Bio</label>
          <InputTextarea name="biography" id="bio" value={updatedUser.biography} onChange={handleChange} aria-describedby="username-help" />
        </div>
        <Button type="submit" label="Modifier" loading={isLoading} disabled={isLoading} />
      </form>
    </DialogPanel>
  )
}