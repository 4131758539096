import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, Route, Routes} from "react-router-dom";
import PostsView from "./views/PostsView";
import Avatar from "./components/Avatar";
import User from "./views/User";
import Login from "./views/Login";
import Logout from "./views/Logout";
import { useEffect, useState} from "react";
import PostView from "./views/PostView";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import NotificationsPanel from "./components/NotificationsPanel";
import PostPanel from "./components/PostPanel";
import ProfilePanel from "./components/ProfilePanel";
import SearchPanel from "./components/SearchPanel";
import UpdateProfilePanel from "./components/UpdateProfilePanel";
import useCurrentUser from "./hooks/useCurrentUser";
import {Badge} from "primereact/badge";
import Register from "./views/Register";

function App() {
  const [user, setUser] = useState({})
  const [isNotificationPanelVisible, setIsNotificationPanelVisible] = useState(false)
  const [isPostPanelVisible, setIsPostPanelVisible] = useState(false)
  const [isProfilePanelVisible, setIsProfilePanelVisible] = useState(false)
  const [isSearchPanelVisible, setIsSearchPanelVisible] = useState(false)
  const [isUpdateProfilePanelVisible, setIsUpdateProfilePanelVisible] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [countNotifications, setCountNotifications] = useState(0)
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser) {
      countNotification()
    }
  }, [user, notifications])

  const countNotification = () => {
    if (notifications) {
      setCountNotifications(notifications.filter(notification => !notification.read).length)
    }
  }

  const toggleNotification = () => {
    hideAllPanels()
    setIsNotificationPanelVisible(!isNotificationPanelVisible)
  }

  const togglePostPanel = () => {
    hideAllPanels()
    setIsPostPanelVisible(!isPostPanelVisible)
  }

  const toggleProfilePanel = () => {
    hideAllPanels()
    setIsProfilePanelVisible(!isProfilePanelVisible)
  }

  const toggleSearchPanel = () => {
    hideAllPanels()
    setIsSearchPanelVisible(!isSearchPanelVisible)
  }
  
  const toggleUpdateProfilePanel = () => {
    hideAllPanels()
    setIsUpdateProfilePanelVisible(!isUpdateProfilePanelVisible)
  }

  const hideAllPanels = () => {
    setIsNotificationPanelVisible(false)
    setIsPostPanelVisible(false)
    setIsProfilePanelVisible(false)
    setIsSearchPanelVisible(false)
    setIsUpdateProfilePanelVisible(false)
  }

  if (!Object.keys(user).length) {
    if (currentUser) {
      setUser(currentUser)
    }

    return (
      <>
        <Routes>
          <Route path="*" element={<Login user={user} setUser={setUser} />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </>
    )
  }

  return (
    <>
      <header className="w-100 py-3 px-3 d-flex justify-content-between align-items-center bg-white shadow-sm sticky-top" style={{zIndex: '3'}}>
        <Badge value="Beta" severity="info"></Badge>
        <Link to="/" className="text-decoration-none">
          <img src="/favicon.svg" alt="Piouteur" width="32" title="Piouteur" />
        </Link>
        <Avatar user={user} isLink={false } onClick={toggleProfilePanel} />
      </header>
      <main className="w-100 h-100 md:w-1/2" style={{marginBottom: '89px'}}>
        <Routes>
          <Route path="/" element={<PostsView />} />
          <Route path="/user/:username" element={<User toggle={toggleUpdateProfilePanel}/>} />
          <Route path="/post/:id" element={<PostView />} />
          <Route path="/logout" element={<Logout setUser={setUser} />} lazy />
        </Routes>
      </main>
      <NotificationsPanel visible={isNotificationPanelVisible} toggle={toggleNotification} notifications={notifications} setNotifications={setNotifications}  />
      <PostPanel visible={isPostPanelVisible} toggle={togglePostPanel} />
      <ProfilePanel visible={isProfilePanelVisible} toggle={toggleProfilePanel} user={user} />
      <SearchPanel visible={isSearchPanelVisible} toggle={toggleSearchPanel} user={user} />
      <UpdateProfilePanel visible={isUpdateProfilePanelVisible} toggle={toggleUpdateProfilePanel} user={user} setUser={setUser} />
      <footer className="d-flex justify-content-around fixed-bottom w-100 py-4 px-3 bg-white shadow" style={{zIndex: '3000'}}>
        <Link to="/" onClick={hideAllPanels} className="p-button p-component p-button-text text-decoration-none">
          <i className="pi pi-home"></i>
        </Link>
        <button onClick={toggleSearchPanel} className={`p-button p-component ${isSearchPanelVisible ? '' : 'p-button-text'} text-decoration-none`}>
          <i className="pi pi-search"></i>
        </button>
        <button onClick={toggleNotification} className={`p-button p-component ${isNotificationPanelVisible ? '' : 'p-button-text' }`}>
          <i className={`pi pi-bell ${countNotifications !== 0 && `p-overlay-badge`}`}>
            {countNotifications !== 0 && <Badge value={countNotifications} severity="danger"></Badge>}
          </i>
        </button>
        <button onClick={togglePostPanel} className={`p-button p-component ${isPostPanelVisible ? '' : 'p-button-outlined' } text-decoration-none`}>
          <i className="pi pi-plus"></i>
        </button>
      </footer>
    </>
  );
}

export default App;
